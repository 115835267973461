import theme from 'constants/theme';

export const calculateDisplayedItemsCount = (tagsRef, items) => {
  if (tagsRef && tagsRef.current) {
    const percentageOfBadge = (20 * tagsRef.current.offsetWidth) / 100;
    const containerMinusBadge = tagsRef.current.offsetWidth - percentageOfBadge;

    let totalWidth = 0;
    const count = items.reduce((acc, _, index) => {
      const childElement = tagsRef.current.children[index];
      if (childElement) {
        totalWidth += childElement.offsetWidth;
        if (totalWidth > containerMinusBadge) return acc;
        return acc + 1;
      }
      return acc;
    }, 0);

    return count;
  }
  return items.length;
};

export const getTagColors = (skillsPerAssignment, skillName) => {
  const isSkillIncluded = skillsPerAssignment.some(skill => skill.name.includes(skillName));

  return {
    backgroundColor: isSkillIncluded ? theme.new.colors.P500 : theme.new.colors.N300,
    borderColor: isSkillIncluded ? theme.new.colors.P500 : theme.new.colors.N300,
    fontColor: isSkillIncluded ? theme.new.colors.white : theme.new.colors.black
  };
};

export const capitalizeFirstLetter = value => {
  if (!value || typeof value !== 'string') {
    return '-';
  }
  return value.charAt(0).toUpperCase() + value.slice(1);
};
export const parseAvailabilityRange = rangeString => {
  const [min, max] = rangeString.split('-').map(Number);
  return { min, max };
};

export const isInRange = (value, range) => value >= range.min && value <= range.max;

export const isActive = (filter, value) => filter.length === 0 || filter.includes(value);

export const orderItemsByRequirement = (items, skillsPerAssignment) => {
  if (skillsPerAssignment === undefined) {
    return []
  }

  const skillsSet = new Set(skillsPerAssignment.map(skill => skill.name));

  return [...items].sort((a, b) => {
    const aMatches = skillsSet.has(a.skillName);
    const bMatches = skillsSet.has(b.skillName);
    return bMatches - aMatches;
  });
};
